<template>
  <n-drawer v-model:show="showMaintenanceDrawer" :width="isMobile ? '85%' : '30%'">
    <n-drawer-content :closable="isMobile">
      <n-thing v-if="!addingMaintenanceInProcess">
        <template #avatar>
          <n-avatar class="avatar">
            <n-icon>
              <MaintenanceIcon />
            </n-icon>
          </n-avatar>
        </template>
        <template #header>{{
          t("MaintainerPage.PublicationPage.StatsAndCalendarPage.maintenanceHistoryTitle")
        }}</template>
        <Table
          :data="maintenanceList"
          :fieldsToHide="['id']"
          :globalActions="maintenanceGlobalActions"
          :itemsActions="maintenanceItemActions"
        />
      </n-thing>
      <n-thing v-else>
        <template #header>{{
          t("MaintainerPage.PublicationPage.StatsAndCalendarPage.rangeDaysTitle")
        }}</template>
        <n-space justify="center">
          <date-selector
            v-if="model"
            :publication="model"
            v-model:value="newMaintenanceRange"
          />
        </n-space>
        <template #action>
          <n-space justify="center">
            <n-button @click="cancelAddNewMaintenance">
              <template #icon>
                <n-icon>
                  <CancelIcon />
                </n-icon>
              </template>
              {{ t("MaintainerPage.PublicationPage.StatsAndCalendarPage.cancelButton") }}
            </n-button>
            <n-button @click="addNewMaintenance" :disabled="!canAddNewDateForMaintenace">
              <template #icon>
                <n-icon :color="themeOverrides.common.successColor">
                  <NewItemIcon />
                </n-icon>
              </template>
              {{ t("MaintainerPage.PublicationPage.StatsAndCalendarPage.addButton") }}
            </n-button>
          </n-space>
        </template>
      </n-thing>
    </n-drawer-content>
  </n-drawer>
  <n-grid cols="1 s:6" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:4" offset="0 s:1">
      <n-card>
        <n-page-header>
          <n-grid :cols="stats?.length">
            <n-gi v-for="stat in stats" :key="stat.label">
              <n-statistic :label="stat.label" :value="stat.value" />
            </n-gi>
          </n-grid>
          <template #title>
            <a @click="goToPublication(model.id)">
              <n-h1>{{ model?.title }} </n-h1>
            </a>
          </template>

          <template #extra>
            <n-space>
              <n-button @click="showMaintenanceDrawer = true">
                <template #icon>
                  <n-icon>
                    <MaintenanceIcon />
                  </n-icon>
                </template>
                {{
                  t(
                    "MaintainerPage.PublicationPage.StatsAndCalendarPage.maintenanceButton"
                  )
                }}
              </n-button>
            </n-space>
          </template>
        </n-page-header>
      </n-card>
    </n-gi>
  </n-grid>
  <n-grid cols="1 s:6" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:4" offset="0 s:1">
      <div>
        <n-calendar #="{ year, month, date }" v-model:value="value">
          <n-space vertical>
            <span
              class="avantar-calendar"
              v-for="item in contentToShow(year, month, date)"
              :key="item"
            >
              <n-avatar :style="item.style">{{ item.event }}</n-avatar>
            </span>
          </n-space>
        </n-calendar>
      </div>
    </n-gi>
  </n-grid>
</template>

<script>
import { defineComponent, ref, onMounted, computed, h, watch } from "vue";
import {
  useDialog,
  useMessage,
  NCalendar,
  NPageHeader,
  NDrawer,
  NDrawerContent,
  NThing,
  NAvatar,
  NIcon,
  NSpace,
  NButton,
  NGrid,
  NGi,
  NCard,
  NStatistic,
  NH1,
} from "naive-ui";
import responsive from "@/mixins/responsive";
import { useStore } from "vuex";
import { MaintenanceState } from "@/models/index";
import {
  ConstructOutline as MaintenanceIcon,
  AddOutline as NewItemIcon,
  CloseOutline as CancelIcon,
  CheckmarkOutline as DoneIcon,
} from "@vicons/ionicons5";
import { useRouter } from "vue-router";
import { titleCase } from "title-case";
import Table from "@/components/Table.vue";
import DateSelector from "@/components/publication/DateSelector/Index.vue";
import { eachDayOfInterval, isSameDay, addDays, parseISO, formatISO } from "date-fns";
import { getHistory, EventType } from "@/shared/calendar";
import { themeOverrides } from "@/shared/constants";
import { useI18n } from "vue-i18n";

const demo_data = {
  id: "6c60d425-0a4f-4a8e-bd38-0eb064d3d71c",
  title: "Taladro 7",
  state: "ACTIVE",
  createdAt: "2022-01-01T20:18:53.295Z",
  updatedAt: "2022-01-30T20:19:03.164Z",
  rents: {
    items: [
      {
        id: "1",
        state: "RETURNED",
        requestedRangeOfDates: {
          start: "2022-01-03T00:00:00.000Z",
          end: "2022-01-06T00:00:00.000Z",
        },
        executedRangeOfDates: {
          start: "2022-01-03T00:00:00.000Z",
          end: "2022-01-06T00:00:00.000Z",
        },
      },
      {
        id: "2",
        state: "IN_PROCESS",
        requestedRangeOfDates: {
          start: "2022-01-15T00:00:00.000Z",
          end: "2022-01-18T00:00:00.000Z",
        },
        executedRangeOfDates: {
          start: "2022-01-15T00:00:00.000Z",
        },
      },
      {
        id: "3",
        state: "PENDING",
        requestedRangeOfDates: {
          start: "2022-01-19T00:00:00.000Z",
          end: "2022-01-23T00:00:00.000Z",
        },
      },
    ],
  },
  maintenances: [
    {
      id: "1",
      rangeOfDates: {
        start: "2022-01-07T00:00:00.000Z",
        end: "2022-01-09T00:00:00.000Z",
      },
      state: "DONE",
    },
    {
      id: "2",
      rangeOfDates: {
        start: "2022-01-24T00:00:00.000Z",
        end: "2022-01-28T00:00:00.000Z",
      },
      state: "PENDING",
    },
  ],
};

export default defineComponent({
  components: {
    NCalendar,
    MaintenanceIcon,
    NPageHeader,
    NDrawer,
    NDrawerContent,
    Table,
    NThing,
    NAvatar,
    CancelIcon,
    NewItemIcon,
    NIcon,
    NSpace,
    NButton,
    NGrid,
    NGi,
    NCard,
    NStatistic,
    NH1,
    DateSelector,
  },
  props: {
    publicationId: { type: String, required: true },
    publication: { type: Object, required: false, default: null },
    demo: { type: Boolean, required: false, default: false },
  },
  mixins: [responsive],
  data() {
    return {
      themeOverrides,
    };
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const isLoadingRef = ref(false);
    const store = useStore();
    const router = useRouter();
    const dialog = useDialog();
    const addingMaintenanceInProcess = ref(false);
    const newMaintenanceRange = ref(null);
    const message = useMessage();

    const modelRef = ref({
      checkListFields: [],
      category: "",
      preferredUnitTime: "DAY",
      costs: { day: 0, week: 0, month: 0, year: 0 },
      calculatedCosts: { day: 0, week: 0, month: 0, year: 0 },
      title: "",
    });
    const TagStyles = {
      CREATED: {
        color: themeOverrides.common.whiteColor,
        backgroundColor: themeOverrides.common.successColor,
      },
      RESERVED: {
        color: themeOverrides.common.whiteColor,
        backgroundColor: themeOverrides.common.infoColor,
      },
      RENTED: {
        color: themeOverrides.common.whiteColor,
        backgroundColor: themeOverrides.common.greenColor,
      },
      MAINTENANCE: {
        color: themeOverrides.common.whiteColor,
        backgroundColor: themeOverrides.common.grayMediumColor,
      },
      DELAYED: {
        color: themeOverrides.common.whiteColor,
        backgroundColor: themeOverrides.common.grayMediumColor,
      },
      FINISHED: {
        color: themeOverrides.common.whiteColor,
        backgroundColor: themeOverrides.common.redColor,
      },
      ARBITRATION_IN_PROCESS: {
        color: themeOverrides.common.whiteColor,
        backgroundColor: themeOverrides.common.orangeColor,
      },
    };

    const history = computed(() => {
      return getHistory(modelRef.value);
    });

    const stats = computed(() => {
      let stats = [];
      stats.push({
        label: t("commons.labels.status"),
        value: titleCase(
          modelRef.value?.state ? t(`publications.states.${modelRef.value.state}`) : ""
        ),
      });

      stats.push({
        label: t("statsAndCalendar.labels.rentedDays"),
        value: history.value.filter((item) => item.event === EventType.RENTED).length,
      });

      stats.push({
        label: t("statsAndCalendar.labels.reservedDays"),
        value: history.value.filter((item) => item.event === EventType.RESERVED).length,
      });

      stats.push({
        label: t("statsAndCalendar.labels.maintenanceDays"),
        value: history.value.filter((item) => item.event === EventType.MAINTENANCE)
          .length,
      });

      if (history.value.filter((item) => item.event === EventType.DELAYED).length > 0) {
        stats.push({
          label: t("statsAndCalendar.labels.delayedDays"),
          value: history.value.filter((item) => item.event === EventType.DELAYED).length,
        });
      }

      return stats;
    });

    const showMaintenanceDrawer = ref(false);

    watch(showMaintenanceDrawer, (showMaintenanceDrawer) => {
      if (!showMaintenanceDrawer) {
        addingMaintenanceInProcess.value = false;
        newMaintenanceRange.value = null;
      }
    });

    onMounted(async () => {
      isLoadingRef.value = true;
      const selectedPublication = props.demo
        ? demo_data
        : props.publication !== null
        ? props.publication
        : await store.dispatch(
            "maintainer_publication/getPublicationsRentsAndMaintenancesByPublicationId",
            props.publicationId
          );

      modelRef.value = selectedPublication;
    });

    const updatePublicationMaintenances = (newMaintenancesData) => {
      store
        .dispatch("maintainer_publication/updatePublicationMaintenances", {
          publicationId: modelRef.value.id,
          maintenances: newMaintenancesData,
          version: modelRef.value._version,
        })
        .then((result) => {
          modelRef.value.maintenances = result.maintenances.map((maintenance) => {
            maintenance.rangeOfDates.start = parseISO(
              maintenance.rangeOfDates.start.split("T")[0]
            );
            maintenance.rangeOfDates.end = parseISO(
              maintenance.rangeOfDates.end.split("T")[0]
            );
            return maintenance;
          });
          modelRef.value._version = result.version;
        })
        .catch((err) => {
          console.error(err);
          message.error(
            t("commons.errors.updating", {
              reference: t("commons.labels.maintenance", 1),
            })
          );
          throw err;
        })
        .finally(() => {
          newMaintenanceRange.value = null;
          addingMaintenanceInProcess.value = false;
        });
    };

    return {
      t,
      value: ref(null),
      model: modelRef,
      stats,
      contentToShow(year, month, date) {
        const currentDate = new Date(year, month - 1, date);
        return history.value
          .filter((item) => isSameDay(item.date, currentDate))
          .map((item) => Object.assign({}, item, { style: TagStyles[item.event] }));
      },
      goToPublication(id) {
        router.push({ name: "view-publication", params: { publicationId: id } });
      },
      showMaintenanceDrawer,
      addDays,
      maintenanceList: computed(() => {
        return modelRef.value.maintenances
          ?.filter((maintenance) => maintenance.state != MaintenanceState.DELETED)
          .map((item) => {
            return {
              id: item.id,
              From: formatISO(item.rangeOfDates.start, {
                representation: "date",
              }),
              To: formatISO(item.rangeOfDates.end, { representation: "date" }),
              Status: item.state,
            };
          });
      }),
      maintenanceGlobalActions: computed(() => {
        return modelRef.value.state != "INACTIVE"
          ? [
              {
                label: t("commons.actions.add"),
                key: "add",
                icon: NewItemIcon,
                props: {
                  onClick: () => (addingMaintenanceInProcess.value = true),
                },
              },
            ]
          : null;
      }),
      maintenanceItemActions: (item) => {
        if (modelRef.value.state === "INACTIVE") {
          return null;
        }
        let actions = [];
        if (item.Status != MaintenanceState.DONE) {
          actions.push({
            label: t("commons.confirmation.done"),
            key: "done",
            icon: DoneIcon,
            props: {
              onClick: () => {
                dialog.warning({
                  title: t("commons.labels.confirmation"),
                  content: () =>
                    h(
                      "div",
                      {},
                      t("statsAndCalendar.dialogs.confirmMaintenanceDone", {
                        from: item.From,
                        to: item.To,
                      })
                    ),
                  positiveText: t("commons.confirmation.yes"),
                  negativeText: t("commons.confirmation.no"),
                  onPositiveClick: () => {
                    const newMaintenancesData = modelRef.value.maintenances.map((el) =>
                      el.id == item.id ? { ...el, state: MaintenanceState.DONE } : el
                    );
                    updatePublicationMaintenances(newMaintenancesData);
                  },
                });
              },
            },
          });
          actions.push({
            label: t("commons.actions.cancel"),
            key: "cancel",
            icon: CancelIcon,
            props: {
              onClick: () => {
                dialog.warning({
                  title: t("commons.labels.confirmation"),
                  content: () =>
                    h(
                      "div",
                      {},
                      t("statsAndCalendar.dialogs.confirmCancelMaintenance", {
                        from: item.From,
                        to: item.To,
                      })
                    ),
                  positiveText: t("commons.confirmation.yes"),
                  negativeText: t("commons.confirmation.no"),
                  onPositiveClick: () => {
                    const newMaintenancesData = modelRef.value.maintenances.map((el) =>
                      el.id == item.id ? { ...el, state: MaintenanceState.DELETED } : el
                    );
                    updatePublicationMaintenances(newMaintenancesData);
                  },
                });
              },
            },
          });
        }

        return actions;
      },
      addingMaintenanceInProcess,
      newMaintenanceRange,
      addNewMaintenance: () => {
        const newMaintenancesData = [
          ...modelRef.value.maintenances,
          {
            id: modelRef.value.maintenances.length + 1,
            rangeOfDates: newMaintenanceRange.value,
            state: MaintenanceState.PENDING,
          },
        ];

        updatePublicationMaintenances(newMaintenancesData);
      },
      disabledDatesForMaintenance: computed(() => {
        return history.value.map((a) => a.date);
      }),
      canAddNewDateForMaintenace: computed(() => {
        if (newMaintenanceRange.value) {
          const daysInRange = eachDayOfInterval(newMaintenanceRange.value);
          try {
            history.value.forEach((day) => {
              daysInRange.forEach((newDay) => {
                if (isSameDay(day.date, newDay)) {
                  throw new Error(
                    t("commons.errors.bad", { reference: t("commons.labels.date", 2) })
                  );
                }
              });
            });
          } catch (e) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      }),
      cancelAddNewMaintenance: () => {
        addingMaintenanceInProcess.value = false;
        newMaintenanceRange.value = null;
      },
    };
  },
});
</script>

<style scoped>
.avatar {
  color: v-bind(themeOverrides.common.successColor);
  backgroundcolor: v-bind(themeOverrides.common.whiteColor);
}

.avantar-calendar .n-avatar {
  width: 100%;
  @media screen and (max-width: 799px) {
    height: 14px;
    font-size: 10px;
  }
}
:deep(.n-thing .n-thing-avatar-header-wrapper) {
  align-items: center;
}

.rented {
  background-color: v-bind(themeOverrides.common.redColor);
}

.n-calendar {
  @media screen and (max-width: 799px) {
    max-height: 320px;
  }
}

:deep(.n-calendar-date) {
  padding: 10px;
}

:deep(.n-calendar-cell) {
  padding: unset;
  @media screen and (max-width: 799px) {
    max-height: 55px;
  }
}

:deep(.n-calendar .n-calendar-cell .n-calendar-date .n-calendar-date__date) {
  @media screen and (max-width: 799px) {
    font-size: 11px;
  }
}
:deep(.n-calendar-date__day) {
  @media screen and (max-width: 799px) {
    font-size: 11px;
  }
}
</style>
